import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components';

import { Link } from 'gatsby'

import Layout from '../../components/Layout'
import Grid from '../../components/Helpers/Grid'

import './style.css';

const SectionLink = styled(Link)`
    align-items: center;
    &:hover {
      color: #a5a5a5;  
      background-color: gray;      
    }  /* mouse over link */
`

const DestinationWraper = styled.div`
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  height: auto;
  width: 70vw;  
  @media screen and (max-width: 960px) {
    width: 90vw;  
    margin-bottom: 40px;
  }  
`

const ColumnWrapper = styled.div`
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
`

const ProductCard = styled.div`
  display: block;
  flex-direction: column;
  margin-bottom: 2px;
  //border: 1px solid red;
  cursor: pointer;
  justify-content: flex-start;
  text-align: center;
  margin-bottom: 40px;  

  img {
    height: 300px; 
    width: 300px;
    object-fit: cover; 
    border-radius: 50%;
    background-color: #f1f1f1;
  }

  @media screen and (max-width: 960px) {
    img {
      height: 200px; 
      width: 200px;
    }
  }  

`



const Destinations = () => {

  return (
    <DestinationWraper>
        <h1>Travel</h1>
    </DestinationWraper>          
    )
}

const BuenosAires = () => {

  return (
      <DestinationWraper>  
        <Grid columns={3} style={{ textAlign: "center" }}>           
        <ColumnWrapper>
            <SectionLink to="/travel/santaelena">
              <ProductCard>
                <StaticImage src="../../images/travel/santaelena.png"  layout="constrained" width={300}  height={300} />
                <h2>Santa Elena</h2>
                <p>Travel info to Santa Elena</p>
              </ProductCard>
            </SectionLink>
          </ColumnWrapper>                         
          <ColumnWrapper>
            <SectionLink to="/travel/whattosee">
              <ProductCard>
                <StaticImage src="../../images/travel/travel.png"  layout="constrained" width={300}  height={300} />
                <h2>What to see</h2>
                <p>Attractions in Buenos Aires</p>
              </ProductCard>
            </SectionLink>
          </ColumnWrapper>        
          <ColumnWrapper>
            <SectionLink to="/travel/wheretoeat">
              <ProductCard>
                <StaticImage src="../../images/travel/dish.png"  layout="constrained" width={300}  height={300} />
                <h2>Where to eat</h2>
                <p>Best Restaurants in town</p>
              </ProductCard>            
            </SectionLink>
          </ColumnWrapper>
          <ColumnWrapper>
            <SectionLink to="/travel/wheretodrink">
              <ProductCard>
                <StaticImage src="../../images/travel/drink.png"  layout="constrained" width={300}  height={300} />
                <h2>Where to drink</h2>
                <p>Bars in the city that never sleeps</p>
              </ProductCard>
            </SectionLink>
          </ColumnWrapper>                             
          <ColumnWrapper>
            <SectionLink to="/travel/weather">
              <ProductCard>
                <StaticImage src="../../images/travel/weather.png"  layout="constrained" width={300}  height={300} />
                 <h2>What about Weather</h2>
                 <p>Weather info and what to wear</p>
              </ProductCard>
            </SectionLink>
          </ColumnWrapper>                        
          <ColumnWrapper>
            <SectionLink to="/travel/destinations">
              <ProductCard>
                <StaticImage src="../../images/travel/flight.png" layout="constrained"  width={300}  height={300} />
                <h2>Destinations</h2>
                <p>Travel within Argentina</p>
              </ProductCard>
            </SectionLink>
          </ColumnWrapper>            
          <ColumnWrapper>
            <SectionLink to="/travel/hotels">
              <ProductCard>
                <StaticImage src="../../images/travel/stay.png"  layout="constrained" width={300}  height={300} />
                <h2>Where to stay</h2>
                <p>Hotel options in Bs As</p>
              </ProductCard>
            </SectionLink>
          </ColumnWrapper>              
        </Grid>        
      </DestinationWraper>
    )
}


const Travel = () => {

  return (
    <Layout section="travel" pageTitle="Travel">
      <BuenosAires />      
    </Layout>
  )
}

export default Travel